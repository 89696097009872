import { forwardRef } from "react"
import { TextInput, TextInputProps, IconCalendar } from "suomifi-ui-components"

type Props = TextInputProps & { onChange?: (value: { target: { value: string } }) => void }

const DatePickerInput = forwardRef<HTMLInputElement, Props>(({ onChange, ...passProps }, ref) => (
  <TextInput
    {...passProps}
    icon={<IconCalendar />}
    ref={ref}
    onChange={value =>
      (onChange as (value: { target: { value: string | number | undefined } }) => void)({ target: { value } })
    }
  />
))

DatePickerInput.displayName = "DatePickerInput"

export { DatePickerInput }

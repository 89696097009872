import { useContext, useEffect, useState } from "react"
import { UNSAFE_NavigationContext as NavigationContext } from "react-router"
import { History, Transition } from "history"

type BlockingNavigator = Navigator & Pick<History, "block">
type Block = { unblock: () => void }

const useBlocker = () => {
  const navigator = useContext(NavigationContext).navigator as unknown as BlockingNavigator
  const [transition, setTransition] = useState<Transition>()
  const [block, setBlock] = useState<Block>()

  useEffect(() => {
    const unblock = navigator.block(setTransition)
    setBlock({ unblock })
    return unblock
  }, [navigator])

  const cancel = () => {
    setTransition(undefined)
  }

  const confirm = () => {
    ;(block as Block).unblock()
    ;(transition as Transition).retry()
  }

  return { isBlocked: transition !== undefined, confirm, cancel }
}

export { useBlocker }

import { Route, Routes } from "react-router-dom"
import { UsersCreatePage } from "./UsersCreatePage"
import { UsersList } from "./UsersList"
import { UserEditPage } from "./UserEditPage"

const UsersRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<UsersList />} />
      <Route path="/:id" element={<UserEditPage />} />
      <Route path="/new" element={<UsersCreatePage />} />
    </Routes>
  )
}

export { UsersRoute }

import styles from "./AppRoutes.module.scss"
import { useMemo } from "react"
import { NavBar } from "../NavBar/NavBar"
import { Route, Routes, Navigate } from "react-router-dom"
import { ServicesRoute } from "../ServicesPage/ServicesRoute"
import { UsersRoute } from "../UsersPage/UsersRoute"
import { NotificationsRoute } from "../Notifications/NotificationsRoute"
import { AdminUiNotifications } from "../AdminUiNotifications/AdminUiNotifications"
import { useAuthorizations } from "../hooks"
import { notifications, officers, organizations, services, users } from "../utils/routerPaths"
import { OfficersRoute } from "../Officers/OfficersRoute"
import OrganizationsPage from "../Organizations/OrganizationsPage"

const AppRoutes = () => {
  const { data: authorizations } = useAuthorizations()

  const usersDefaultRoute = useMemo(() => {
    if (authorizations) {
      if (authorizations.includes("SERVICEMGMT") || authorizations.includes("RULEMGMT")) {
        return "/services"
      } else if (authorizations.includes("USERMGMT")) {
        return "/users"
      } else if (authorizations.includes("OFFICERADMIN")) {
        return "/officers"
      } else if (authorizations.includes("NOTIFICATIONMGMT")) {
        return "/notifications"
      }
    }
  }, [authorizations])

  return (
    <>
      {authorizations && <NavBar authorizations={authorizations} />}
      <AdminUiNotifications />
      <div className={`container my-l py-xs ${styles.content}`}>
        <Routes>
          <Route path={users} element={<UsersRoute />} />
          <Route path={notifications} element={<NotificationsRoute />} />
          <Route path={officers} element={<OfficersRoute />} />
          <Route path={services} element={<ServicesRoute />} />
          <Route path={organizations} element={<OrganizationsPage />} />
          {usersDefaultRoute && <Route path="/" element={<Navigate to={usersDefaultRoute} replace />} />}
        </Routes>
      </div>
    </>
  )
}

export { AppRoutes }

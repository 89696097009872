import { useBlocker } from "../hooks/useBlocker"
import { Button, Modal, ModalContent, ModalFooter, ModalTitle } from "suomifi-ui-components"
import { FormattedMessage } from "react-intl"

const NavigationBlocker = () => {
  const { isBlocked, confirm, cancel } = useBlocker()

  return (
    <Modal appElementId="root" visible={isBlocked}>
      <ModalContent>
        <ModalTitle>
          <FormattedMessage id="admin.warn.navigation.header" />
        </ModalTitle>
        <FormattedMessage id="admin.warn.navigation.message" />
      </ModalContent>
      <ModalFooter>
        <Button data-testid="confirm-navigation-yes-button" onClick={confirm}>
          <FormattedMessage id="admin.button.kylla" />
        </Button>
        <Button data-testid="confirm-navigation-no-button" onClick={cancel} variant="secondary">
          <FormattedMessage id="admin.button.ei" />
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export { NavigationBlocker }

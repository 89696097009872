import { Button, Checkbox, CheckboxGroup, StatusText, Textarea } from "suomifi-ui-components"
import { FormattedMessage, useIntl } from "react-intl"
import { useEffect, useMemo } from "react"
import { Notification } from "../types"
import "react-datepicker/dist/react-datepicker.css"
import { NavigationPrompt } from "../components/NavigationPrompt"
import { Controller, ControllerRenderProps, useForm } from "react-hook-form"
import DatePicker from "react-datepicker"
import { DatePickerInput } from "../components/DatePickerInput"
import { NavButton } from "../components/NavButton"

const channelOptions = ["VARE-UI", "VARE-UI-OFFICER", "WEB-API", "ADMIN-UI"]

type Props = {
  initialNotification: Notification
  onSubmit: (notification: Notification) => void
}

type NotificationFormValues = {
  id?: number
  text: string
  startDate: Date | null
  endDate: Date | null
  channels: string[]
}

const initNotificationForm: (notification: Notification) => NotificationFormValues = notification => {
  return {
    id: notification.id,
    text: notification.text,
    startDate: new Date(notification.startDate),
    endDate: new Date(notification.endDate),
    channels: notification.channels
  }
}

const toNotification = (notificationForm: NotificationFormValues) => {
  return {
    id: notificationForm.id,
    text: notificationForm.text,
    startDate: (notificationForm.startDate as Date).getTime(),
    endDate: (notificationForm.endDate as Date).getTime(),
    channels: notificationForm.channels
  }
}

const NotificationForm = ({ initialNotification, onSubmit }: Props) => {
  const { formatMessage } = useIntl()
  const initialValues = useMemo(() => initNotificationForm(initialNotification), [initialNotification])

  const {
    control,
    register,
    reset,
    getValues,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors, isDirty }
  } = useForm({ defaultValues: initialValues, mode: "onBlur" })

  useEffect(() => reset(initialValues), [initialValues, reset])

  const selectAllChannels = ({ checkboxState }: { checkboxState: boolean }) => {
    setValue("channels", checkboxState ? channelOptions : [], { shouldValidate: true })
  }

  const endDateBeforeStart = (value: Date | null) => {
    const startDate = getValues().startDate
    if (startDate && value && value.getTime() < startDate.getTime()) {
      return "admin.validation.endDateBeforeStart"
    }
  }

  const handleStartDateBlur = async (field: ControllerRenderProps<NotificationFormValues, "startDate">) => {
    field.onBlur()
    await trigger("endDate")
  }

  return (
    <div className="my-3">
      <NavigationPrompt shouldPrompt={isDirty} />
      <p>
        <FormattedMessage id="admin.field.hairio.ohje" />
      </p>

      <form onSubmit={handleSubmit(data => onSubmit(toNotification(data)))}>
        <Textarea
          maxLength={5000}
          rows={4}
          fullWidth
          labelText={formatMessage({ id: "admin.field.hairio.kuvaus" })}
          status={errors.text ? "error" : "default"}
          statusText={errors.text && formatMessage({ id: errors.text.message })}
          {...register("text", { required: "admin.validation.required" })}
        />
        <div className="d-flex flex-column flex-sm-row gap-s my-s">
          <div>
            <Controller
              name="startDate"
              control={control}
              rules={{ required: "admin.validation.required" }}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  showTimeSelect
                  ref={field.ref}
                  name={field.name}
                  selected={field.value}
                  onChange={field.onChange}
                  onBlur={() => handleStartDateBlur(field)}
                  onCalendarClose={() => handleStartDateBlur(field)}
                  dateFormat="dd.MM.yyyy HH:mm"
                  customInput={
                    <DatePickerInput
                      data-testid="nofication-form-start-date"
                      labelText={formatMessage({ id: "admin.field.julkaisu.alkaa" })}
                      status={error ? "error" : "default"}
                      statusText={error && formatMessage({ id: error.message })}
                      style={{ width: 200 }}
                    />
                  }
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="endDate"
              control={control}
              rules={{ required: "admin.validation.required", validate: endDateBeforeStart }}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  showTimeSelect
                  ref={field.ref}
                  name={field.name}
                  selected={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  onCalendarClose={field.onBlur}
                  dateFormat="dd.MM.yyyy HH:mm"
                  customInput={
                    <DatePickerInput
                      data-testid="nofication-form-end-date"
                      labelText={formatMessage({ id: "admin.field.julkaisu.paattyy" })}
                      status={error ? "error" : "default"}
                      statusText={error && formatMessage({ id: error.message })}
                      style={{ width: 200 }}
                    />
                  }
                />
              )}
            />
          </div>
        </div>

        <div className="my-s">
          <CheckboxGroup labelText={formatMessage({ id: "admin.field.julkaisukanava" })}>
            <Checkbox checked={getValues().channels.length === channelOptions.length} onClick={selectAllChannels}>
              <FormattedMessage id="ALL" />
            </Checkbox>
            {channelOptions.map(channel => (
              <Controller
                key={channel}
                name="channels"
                control={control}
                rules={{ required: "admin.validation.required" }}
                render={({ field, fieldState }) => (
                  <Checkbox
                    ref={field.ref}
                    name={field.name}
                    value={channel}
                    onClick={({ checkboxState }) => {
                      field.onChange(
                        checkboxState ? field.value.concat(channel) : field.value.filter((v: string) => v !== channel)
                      )
                      field.onBlur()
                    }}
                    checked={field.value.includes(channel)}
                    status={fieldState.error ? "error" : "default"}
                  >
                    <FormattedMessage id={channel} />
                  </Checkbox>
                )}
              />
            ))}
            <StatusText status="error">
              {errors.channels && <FormattedMessage id={errors.channels.message} />}
            </StatusText>
          </CheckboxGroup>
        </div>

        <div className="mt-l">
          <Button data-testid="notification-form-publish-button" type="submit" className="me-m">
            <FormattedMessage id="admin.button.julkaise" />
          </Button>
          <NavButton data-testid="notification-form-go-back-button" to="/notifications" variant="secondaryNoBorder">
            <FormattedMessage id="admin.button.takaisin" />
          </NavButton>
        </div>
      </form>
    </div>
  )
}

export { NotificationForm }

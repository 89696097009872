import { format } from "date-fns"
import fiLocale from "date-fns/locale/fi"
import svLocale from "date-fns/locale/sv"
const DATE_FORMAT = "P"

const formatDate = (date: number, lang: string): string => {
  return format(new Date(date), DATE_FORMAT, { locale: lang === "fi" ? fiLocale : svLocale })
}

export { formatDate }

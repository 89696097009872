import {
  AdminUiNotification,
  Issue,
  IssuesMap,
  Notification,
  Officer,
  RuleSetMap,
  Service,
  ServiceCreate,
  User,
  AuthorizationResponse,
  AuthorizationRequest,
  IdentityResponse,
  RuleSetsByService,
  Organization,
  OrganizationForm
} from "./types"
import { getToken } from "./utils/csrf"
import { landing, unauthorized } from "./utils/routerPaths"
import { StatusError } from "./StatusError"
import { ApiError } from "./utils/error"
export const getDisplayName = () => {
  return fetch("/admin/users/current/displayName").then(handleTextResponse)
}

export const getLocalization = (locale: string) => {
  return fetch(`/admin/localization/listall/${locale}`).then(handleJsonResponse<Record<string, string>>)
}

export const getAuthorizations = (): Promise<string[]> => {
  return fetch(`/admin/users/user/authorizations`).then(handleJsonResponse<string[]>)
}

export const getAdminUiNotifications = () => {
  return fetch("/admin/notifications/admin-ui").then(handleJsonResponse<AdminUiNotification[]>)
}

export const getServices = () => {
  return fetch("/admin/service").then(handleJsonResponse<Service[]>)
}

export const getService = (id: unknown) => {
  return fetch(`/admin/service/fi.vm.kapa.rova:roles-auths-admin-ui:jar:1.0-SNAPSHOT`).then(handleJsonResponse<Service>)
}

export const getServiceAllIssues = () => {
  return fetch("/admin/service/allissues").then(handleJsonResponse<Issue[]>)
}

export const getServiceAllApis = () => {
  return fetch("/admin/service/allapis").then(handleJsonResponse<string[]>)
}

export const createService = (service: ServiceCreate) => {
  return postRequest("/admin/service", service).then(handleJsonResponse<Service>)
}

export const updateService = (service: Service) => {
  return putRequest(`/admin/service/${service.id}`, service).then(handleJsonResponse<Service>)
}

export const deleteService = (id: string) => {
  return deleteRequest(`/admin/service/fi.vm.kapa.rova:roles-auths-admin-ui:jar:1.0-SNAPSHOT`, undefined).then(handleResponse)
}

export const getServiceByUuid = (uuid: unknown) => {
  return fetch(`/admin/service/uuid/${uuid}`).then(handleJsonResponse<Service>)
}

export const getRulesets = () => {
  return fetch("/admin/ruleset/all").then(handleJsonResponse<RuleSetsByService>)
}

export const getRuleset = (serviceUuid: unknown) => {
  return fetch(`/admin/ruleset?serviceUuid=${serviceUuid}`).then(handleJsonResponse<RuleSetMap>)
}

export const getRuleSetAllIssues = (ruleSetType: string, uuid: unknown) => {
  return fetch(`/admin/ruleset/allissues?ruleSetType=${ruleSetType}&serviceUuid=${uuid}`).then(
    handleJsonResponse<IssuesMap>
  )
}

export const updateRuleSets = (variables: { uuid: string; ruleSets: RuleSetMap }) => {
  return putRequest(`/admin/ruleset?serviceUuid=${variables.uuid}`, variables.ruleSets).then(
    handleJsonResponse<RuleSetMap>
  )
}

export const getUsersForService = (uuid: unknown) => {
  return fetch(`/admin/users/usersForService/${uuid}`).then(handleJsonResponse<User[]>)
}

export const getUsers = () => {
  return fetch("/admin/users/user").then(handleJsonResponse<User[]>)
}

export const getUser = (uuid: unknown) => {
  return fetch(`/admin/users/user/${uuid}`).then(handleJsonResponse<User>)
}

export const createUser = (name: string) => {
  const payload = {
    name,
    userAuthorizations: null,
    userIdentities: null,
    uuid: null
  }
  return postRequest("/admin/users/user", payload).then(handleJsonResponse<User>)
}

export const updateUser = (user: User) => {
  const payload = {
    name: user.name,
    editname: null,
    isMainUser: false,
    userAuthorizations: null,
    userIdentities: null,
    userPermissions: null,
    uuid: user.uuid
  }
  return putRequest(`/admin/users/user/${user.uuid}`, payload).then(handleJsonResponse<User>)
}

export const createUserIdentifier = ({ userIdentifier, uuid }: { userIdentifier: string; uuid: string }) => {
  const payload = {
    id: 0,
    user: {
      editname: "",
      isMainUser: false,
      name: "",
      userAuthorizations: [],
      userIdentities: [],
      userPermissions: [],
      uuid: uuid
    },
    userIdentifier: userIdentifier,
    userIdentifierType: "hetu"
  }
  return postRequest(`/admin/users/identity/${uuid}`, payload).then(handleJsonResponse<IdentityResponse>)
}

export const deleteIdentity = (id: number) => {
  return deleteRequest(`/admin/users/identity/fi.vm.kapa.rova:roles-auths-admin-ui:jar:1.0-SNAPSHOT`).then(handleResponse)
}

export const createUserAuthorization = ({
  authorization,
  uuid
}: {
  authorization: AuthorizationRequest
  uuid: string
}) => {
  return postRequest(`/admin/users/authorization/${uuid}`, authorization).then(
    handleJsonResponse<AuthorizationResponse>
  )
}

export const createUserAuthorizations = ({
  authorizations,
  uuid
}: {
  authorizations: AuthorizationRequest[]
  uuid: string
}) => {
  return Promise.all(authorizations.map(authorization => createUserAuthorization({ authorization, uuid })))
}

export const deleteAuthorization = (id: number) => {
  return deleteRequest(`/admin/users/authorization/fi.vm.kapa.rova:roles-auths-admin-ui:jar:1.0-SNAPSHOT`).then(handleResponse)
}

export const deleteUser = (id: string) => {
  return deleteRequest(`/admin/users/user/fi.vm.kapa.rova:roles-auths-admin-ui:jar:1.0-SNAPSHOT`).then(handleResponse)
}

export const getNotifications = () => {
  return fetch("/admin/notifications/all").then(handleJsonResponse<Notification[]>)
}

export const getNotification = (id: unknown) => {
  return fetch(`/admin/notifications/notification/fi.vm.kapa.rova:roles-auths-admin-ui:jar:1.0-SNAPSHOT`).then(handleJsonOrText<Notification>)
}

export const getOfficers = () => {
  return fetch("/admin/officers/officers").then(handleJsonResponse<Officer[]>)
}

export const deleteOfficer = (uuid: string) => {
  return deleteRequest(`/admin/officers/officer/${uuid}`, undefined).then(handleResponse)
}

export const officer = (officer: Officer) => {
  return putRequest(`/admin/officers/officer`, officer).then(handleResponse)
}

export const updateNotification = (notification: Notification) => {
  return putRequest("/admin/notifications/notification", notification).then(handleJsonResponse<Notification>)
}

export const createNotification = (notification: Notification) => {
  return postRequest("/admin/notifications/notification", notification).then(handleJsonResponse<Notification>)
}

export const getOrganizations = () => {
  return fetch("/admin/organizations").then(handleJsonResponse<Organization[]>)
}

export const createOrganization = (organization: OrganizationForm) => {
  return postRequest("/admin/organizations", organization).then(handleJsonResponse<Organization>)
}

export const updateOrganization = (organization: OrganizationForm) => {
  return putRequest(`/admin/organizations/${organization.id}`, organization).then(handleJsonResponse<Organization>)
}

export const deleteOrganization = (id: number) => {
  return deleteRequest(`/admin/organizations/fi.vm.kapa.rova:roles-auths-admin-ui:jar:1.0-SNAPSHOT`).then(handleResponse)
}

export const getIssues = (): Promise<Issue[]> => {
  return fetch("/admin/officers/issues").then(handleJsonResponse<Issue[]>)
}

const putRequest = (url: string, body: unknown) => {
  return mutateRequest(url, body, "PUT")
}

const postRequest = (url: string, body: unknown) => {
  return mutateRequest(url, body, "POST")
}

const deleteRequest = (url: string, body?: unknown) => {
  return mutateRequest(url, body, "DELETE")
}

const mutateRequest = (url: string, body: unknown, method: string) => {
  return fetch(url, {
    method,
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      "X-XSRF-TOKEN": getToken()
    }
  })
}

const handleTextResponse = (response: Response) => {
  checkApiErrors(response)
  return response.text()
}

const handleJsonResponse: <T>(response: Response) => Promise<T> = async response => {
  checkRedirects(response)
  const body = await response.json()
  if (response.ok) {
    return body
  }
  throw new ApiError(body.errorMessage, response.status, body)
}

const handleResponse = (response: Response) => {
  checkApiErrors(response)
}

const handleJsonOrText: <T>(response: Response) => Promise<T | string> = response => {
  checkApiErrors(response)
  if (response.headers.get("content-type")?.includes("application/json")) {
    return response.json()
  }
  return response.text()
}

const checkRedirects = (response: Response) => {
  if (response.redirected) {
    window.location.href = landing
    throw new Error(response.statusText)
  }
  if (response.status === 403) {
    window.location.href = unauthorized
    throw new Error(response.statusText)
  }
}

const checkApiErrors = (response: Response) => {
  checkRedirects(response)
  if (response.ok) {
    return
  }
  throw new StatusError(response.statusText, response.status)
}

import { Route, Routes } from "react-router-dom"
import { NotificationUpdatePage } from "./NotificationUpdatePage"
import { NotificationsList } from "./NotificationsList"
import { NotificationCreatePage } from "./NotificationCreatePage"

const NotificationsRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<NotificationsList />} />
      <Route path="/new" element={<NotificationCreatePage />} />
      <Route path="/:id" element={<NotificationUpdatePage />} />
    </Routes>
  )
}

export { NotificationsRoute }

import { NavigationBlocker } from "./NavigationBlocker"

type Props = {
  shouldPrompt?: boolean
}

const NavigationPrompt = ({ shouldPrompt }: Props) => {
  if (!shouldPrompt) {
    return null
  }
  return <NavigationBlocker />
}

export { NavigationPrompt }

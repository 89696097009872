import { Heading, TextInput, Button, IconEdit, IconRemove } from "suomifi-ui-components"
import { FormattedMessage, useIntl } from "react-intl"
import { useMemo, useState } from "react"
import { useAuthorizations, useOfficers } from "../hooks"
import { Auth } from "../types"
import { RemoveModal } from "../components/RemoveModal"
import { TimedToast } from "../components/TimedToast"
import useDeleteOfficer from "../hooks/useDeleteOfficer"
import { filterByTerm } from "../utils/filtering"
import { useNavigate } from "react-router-dom"
import { ErrorNotification } from "../components/ErrorNotification"

const OfficersList = () => {
  const [officerFilter, setOfficerFilter] = useState<string | undefined>(undefined)
  const [openRemoveModal, setOpenRemoveModal] = useState<string | undefined>(undefined)

  const navigate = useNavigate()
  const { data: officers } = useOfficers()
  const { data: authorizations } = useAuthorizations()
  const {
    status: deletionStatus,
    mutate: mutateDelete,
    isError: deletionError,
    reset: resetDeletion
  } = useDeleteOfficer()
  const intl = useIntl()

  const orderedOfficers = useMemo(() => {
    if (officers) {
      return officers.sort((a, b) => a.name.localeCompare(b.name))
    }
    return []
  }, [officers])

  const renderOfficers = () => {
    return (
      <table className="table table-striped mt-m">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="admin.field.officer" />
            </th>
            <th>
              <FormattedMessage id="admin.field.organization.id" />
            </th>
            {authorizations && authorizations.includes(Auth.OFFICERADMIN) && (
              <>
                <th>
                  <FormattedMessage id="admin.button.muokkaa" />
                </th>
                <th>
                  <FormattedMessage id="admin.button.poista" />
                </th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {orderedOfficers
            .filter(officer => filterByTerm(officerFilter, officer))
            .map(officer => (
              <tr key={officer.uuid}>
                <td className="w-25 text-break">{officer.name}</td>
                <td className="w-75">{officer.organizationId}</td>
                {authorizations && authorizations.includes(Auth.OFFICERADMIN) && (
                  <>
                    <td>
                      <Button
                        aria-label={intl.formatMessage({ id: "admin.button.muokkaa" })}
                        data-testid={`officers-list-edit-${officer.uuid}`}
                        onClick={() => navigate(`/officers/edit/${officer.uuid}`)}
                        variant="secondaryNoBorder"
                        icon={<IconEdit />}
                      />
                    </td>
                    <td>
                      <Button
                        aria-label={intl.formatMessage({ id: "admin.button.poista" })}
                        data-testid={`officers-list-remove-${officer.uuid}`}
                        onClick={() => {
                          setOpenRemoveModal(officer.uuid)
                        }}
                        variant="secondaryNoBorder"
                        icon={<IconRemove />}
                      />
                    </td>
                    <RemoveModal
                      name={officer.name}
                      visible={openRemoveModal === officer.uuid}
                      header="admin.modal.poistakayttaja.header"
                      confirmationQuestion="admin.modal.poistakayttaja.otsikko"
                      onRemove={() => {
                        setOpenRemoveModal(undefined)
                        mutateDelete(`${officer.uuid}`)
                      }}
                      onCancel={() => setOpenRemoveModal(undefined)}
                    />
                  </>
                )}
              </tr>
            ))}
        </tbody>
      </table>
    )
  }

  const renderNoOfficers = () => {
    return (
      <div className="mt-m">
        <FormattedMessage id="admin.info.ei_virkailijoita" />
      </div>
    )
  }

  const renderNotifications = () => {
    return (
      <>
        {deletionError && (
          <ErrorNotification id="deletion-error-notification" onClose={() => resetDeletion()}>
            <FormattedMessage id="admin.error.kayttajapoisto.epaonnistui" />
          </ErrorNotification>
        )}
        <TimedToast status={deletionStatus}>
          <FormattedMessage id="admin.info.kayttajapoisto.onnistui" />
        </TimedToast>
      </>
    )
  }

  const renderHeading = () => {
    return (
      <div className="d-flex flex-wrap flex-md-nowrap flex-column flex-md-row justify-content-between align-items-md-center gap-m mb-m">
        <Heading variant="h1">
          <FormattedMessage id="admin.header.officers" />
        </Heading>
        <Button data-testid="officers-list-add-button" onClick={() => navigate("/officers/new")}>
          <FormattedMessage id="admin.button.add.officer" />
        </Button>
      </div>
    )
  }

  return (
    <>
      {renderHeading()}
      {renderNotifications()}
      <TextInput
        data-testid="officers-list-filter-input"
        onChange={value => setOfficerFilter(value ? (value as string) : undefined)}
        labelText={intl.formatMessage({ id: "admin.filter.officers" })}
        visualPlaceholder={intl.formatMessage({ id: "admin.info.kirjoita" })}
        fullWidth
      />
      {officers && officers.length > 0 ? renderOfficers() : renderNoOfficers()}
    </>
  )
}

export { OfficersList }

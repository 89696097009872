import { OfficerForm } from "./OfficerForm"
import { Heading } from "suomifi-ui-components"
import { useLocation } from "react-router-dom"
import useOfficers from "../hooks/useOfficers"
import { FormattedMessage } from "react-intl"

const OfficerUpdatePage = () => {
  const { pathname } = useLocation()
  const uuid = pathname.substring(pathname.lastIndexOf("/") + 1)
  const { data: officers } = useOfficers()
  const officer = officers ? officers.filter(o => o.uuid === uuid)[0] : undefined
  return (
    <>
      <Heading variant="h1" className="mb-m">
        <FormattedMessage id="admin.header.update.officer" />
      </Heading>
      {officer && <OfficerForm officer={officer} />}
    </>
  )
}

export { OfficerUpdatePage }

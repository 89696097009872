import { Route, Routes } from "react-router-dom"
import { EditServicePage } from "./EditServicePage"
import { SelectedRulesPage } from "./SelectedRulesPage"
import { ServiceSettings } from "./ServiceSettings"
import { ServicesPage } from "./ServicesPage"

const ServicesRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<ServicesPage />} />
      <Route path="/service/edit/:uuid" element={<EditServicePage />} />
      <Route path="/service/selected-rules/:uuid" element={<SelectedRulesPage />} />
      <Route path="/service/:id/settings" element={<ServiceSettings />} />
    </Routes>
  )
}

export { ServicesRoute }

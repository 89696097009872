import { useMutation, useQueryClient } from "@tanstack/react-query"
import { deleteOfficer } from "../api"

const useDeleteOfficer = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteOfficer,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["officers"] })
    }
  })
}

export default useDeleteOfficer

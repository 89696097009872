import styles from "./App.module.scss"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import I18n from "./I18n"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { AppRoutes } from "./AppRoutes/AppRoutes"
import { Footer } from "./Footer/Footer"
import { fi, sv } from "date-fns/locale"
import { registerLocale, setDefaultLocale } from "react-datepicker"
import { Language } from "./utils/languages"
import { LandingPage } from "./LandingPage/LandingPage"
import { UnauthorizedPage } from "./UnauthorizedPage/UnauthorizedPage"
import { Header } from "./Header/Header"
import { landing, unauthorized } from "./utils/routerPaths"
import { useSessionStorage } from "react-use"
import { useEffect } from "react"

registerLocale("fi", fi)
registerLocale("sv", sv)

const queryClient = new QueryClient()

const App = ({ showTranslationKeys }: { showTranslationKeys: boolean }) => {
  const [locale, setLocale] = useSessionStorage<Language>("lang", "fi")

  useEffect(() => {
    setDefaultLocale(locale)
  }, [locale])

  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <Helmet htmlAttributes={{ lang: locale }} />
        <I18n locale={locale} showTranslationKeys={showTranslationKeys}>
          <BrowserRouter>
            <Header lang={locale} onLangChange={setLocale} />
            <div className={styles.content}>
              <Routes>
                <Route path={landing} element={<LandingPage lang={locale} />} />
                <Route path={unauthorized} element={<UnauthorizedPage />} />
                <Route path="*" element={<AppRoutes />} />
              </Routes>
            </div>
          </BrowserRouter>
          <Footer />
        </I18n>
      </HelmetProvider>
    </QueryClientProvider>
  )
}

export { App }

import { Button, Expander, ExpanderContent, ExpanderTitleButton, Heading, Paragraph, Text } from "suomifi-ui-components"
import { useNavigate, useParams } from "react-router-dom"
import { useServiceByUuid, useRuleSet, useRuleSetAllIssues, useUpdateRuleSets } from "../hooks"
import { IssuesMap, NormalizedRule, RuleSetMap, RuleSetType } from "../types"
import { FormattedMessage } from "react-intl"
import { useEffect, useState } from "react"
import { cloneDeep, isEqual } from "lodash"
import ServiceRule from "./RuleConfigs/ServiceRule"
import GuardianRoleRuleConfig, { GUARDIAN_ROLE_SETTING_KEY } from "./RuleConfigs/GuardianRoleRuleConfig"
import AgeRuleConfig, { AGE_SETTING_KEY, AGE_MIN_SETTING_KEY } from "./RuleConfigs/AgeRuleConfig"
import IssuesRuleConfig, { ISSUES_SETTING_KEY } from "./RuleConfigs/IssuesRuleConfig"
import StatusRuleConfig, { PHASE_SETTING_KEY, STATUS_SETTING_KEY } from "./RuleConfigs/StatusRuleConfig"
import TrusteeshipRuleConfig, { RESTRICTION_CODE_SETTING_KEY } from "./RuleConfigs/TrusteeshipRuleConfig"
import RightLevelRuleConfig, { RIGHTLEVEL_SETTING_KEY } from "./RuleConfigs/RightLevelRuleConfig"
import RoleRuleConfig, {
  ASSOCIATION_ROLES_SETTING_KEY,
  LLCROLES_SETTING_KEY,
  ROLES_SETTING_KEY
} from "./RuleConfigs/RoleRuleConfig"
import { groupRules } from "../utils/ruleSets"
import { NavigationPrompt } from "../components/NavigationPrompt"
import { TimedToast } from "../components/TimedToast"
import { useForm } from "react-hook-form"
import { ErrorNotification } from "../components/ErrorNotification"

const EditServicePage = () => {
  const navigate = useNavigate()
  const { uuid } = useParams()
  const { data: service } = useServiceByUuid(uuid)
  const { data: ruleSets } = useRuleSet(uuid)
  const { status, mutate, isError: updateError, reset } = useUpdateRuleSets()

  const [originalData, setOriginalData] = useState<RuleSetMap>()
  const [modifiedData, setModifiedData] = useState<RuleSetMap>()

  const allIssues = new Map<string, IssuesMap | undefined>()
  allIssues.set(RuleSetType.AUTHORIZATION, useRuleSetAllIssues(RuleSetType.AUTHORIZATION, uuid).data)
  allIssues.set(RuleSetType.ORG_MANDATES, useRuleSetAllIssues(RuleSetType.ORG_MANDATES, uuid).data)
  allIssues.set(RuleSetType.ORG_PERSON_MANDATES, useRuleSetAllIssues(RuleSetType.ORG_PERSON_MANDATES, uuid).data)
  allIssues.set(RuleSetType.ORG_ROLE, useRuleSetAllIssues(RuleSetType.ORG_ROLE, uuid).data)

  const {
    control,
    formState: { isValid }
  } = useForm({ mode: "all" })

  useEffect(() => {
    setOriginalData(ruleSets)
    setModifiedData(cloneDeep(ruleSets))
  }, [ruleSets])

  if (!service || !modifiedData) {
    return null
  }

  const modified = () => {
    return !isEqual(originalData, modifiedData)
  }

  const toggleRuleSelect = (rule: NormalizedRule) => {
    const { type, ruleId } = rule
    const newData = cloneDeep(modifiedData)

    const currentValue = newData[type].rules[ruleId].selected
    newData[type].rules[ruleId].selected = !currentValue
    if (!currentValue) {
      newData[type].ruleConfig[ruleId] = rule.defaultConfiguration
      const issuesByRuleSetType = allIssues.get(type)
      if (issuesByRuleSetType && issuesByRuleSetType[ruleId]) {
        newData[type].ruleConfig[ruleId].allowedissues = issuesByRuleSetType[ruleId].map(issue => issue.uri).join(",")
      }
    } else {
      delete newData[rule.type].ruleConfig[rule.ruleId]
    }

    setModifiedData(newData)
  }

  const changeRuleConfig = (rule: NormalizedRule, key: string, value: string) => {
    const { type, ruleId } = rule
    const data = cloneDeep(modifiedData)
    data[type].ruleConfig[ruleId][key] = value
    if (!value) {
      data[type].rules[ruleId].selected = false
    }
    setModifiedData(data)
  }

  const createServiceConfig = (rule: NormalizedRule) => {
    const { ruleConfig, type } = rule
    const keys = ruleConfig ? Object.keys(ruleConfig) : []
    if (keys.includes(AGE_SETTING_KEY)) {
      return <AgeRuleConfig control={control} rule={rule} onChange={changeRuleConfig} configKey={AGE_SETTING_KEY} />
    } else if (keys.includes(AGE_MIN_SETTING_KEY)) {
      return <AgeRuleConfig control={control} rule={rule} onChange={changeRuleConfig} configKey={AGE_MIN_SETTING_KEY} />
    } else if (keys.includes(GUARDIAN_ROLE_SETTING_KEY)) {
      return <GuardianRoleRuleConfig rule={rule} onClick={changeRuleConfig} />
    } else if (keys.includes(ISSUES_SETTING_KEY)) {
      const map = allIssues.get(type)
      const issues = map ? map[rule.ruleId] : []
      return <IssuesRuleConfig rule={rule} issues={issues} onClick={changeRuleConfig} />
    } else if (keys.includes(STATUS_SETTING_KEY)) {
      return <StatusRuleConfig rule={rule} configKey={STATUS_SETTING_KEY} onClick={changeRuleConfig} />
    } else if (keys.includes(PHASE_SETTING_KEY)) {
      return <StatusRuleConfig rule={rule} configKey={PHASE_SETTING_KEY} onClick={changeRuleConfig} />
    } else if (keys.includes(RESTRICTION_CODE_SETTING_KEY)) {
      return <TrusteeshipRuleConfig rule={rule} configKey={RESTRICTION_CODE_SETTING_KEY} onClick={changeRuleConfig} />
    } else if (keys.includes(RIGHTLEVEL_SETTING_KEY)) {
      return <RightLevelRuleConfig rule={rule} onClick={changeRuleConfig} />
    } else if (keys.includes(ROLES_SETTING_KEY)) {
      return (
        <RoleRuleConfig
          rule={rule}
          configKey={ROLES_SETTING_KEY}
          componentConfig="TJ=TJ,TJS;TIL=T,PTI,LT;ELI=ELI;S=S;IS=IS,PIS;YHM=YHM"
          onClick={changeRuleConfig}
        />
      )
    } else if (keys.includes(LLCROLES_SETTING_KEY)) {
      return (
        <RoleRuleConfig
          rule={rule}
          configKey={LLCROLES_SETTING_KEY}
          componentConfig="PJ=PJ;J=J"
          onClick={changeRuleConfig}
        />
      )
    } else if (keys.includes(ASSOCIATION_ROLES_SETTING_KEY)) {
      return (
        <RoleRuleConfig
          rule={rule}
          configKey={ASSOCIATION_ROLES_SETTING_KEY}
          componentConfig="PJ=PJ;J=J"
          onClick={changeRuleConfig}
        />
      )
    }
    return null
  }

  const createRules = (rules: NormalizedRule[]) => {
    return rules.map(rule => {
      const { type, ruleId } = rule
      const map = allIssues.get(type)
      const disabled = map && map[ruleId] && !map[ruleId].length
      return (
        <div key={`${type}_${ruleId}`}>
          <ServiceRule rule={rule} onClick={toggleRuleSelect} disabled={disabled}>
            {createServiceConfig(rule)}
          </ServiceRule>
        </div>
      )
    })
  }

  const ruleSetExpander = (type: string, ruleTypes: Record<string, Record<string, NormalizedRule[]>>) => {
    return (
      <Expander key={type}>
        <ExpanderTitleButton data-testid={`ruleset-expander-${type}`}>
          <FormattedMessage id={type} />
        </ExpanderTitleButton>
        <ExpanderContent>
          {Object.entries(ruleTypes).map(([ruleType, ruleGroups]) => expanderContent(ruleType, ruleGroups))}
        </ExpanderContent>
      </Expander>
    )
  }

  const expanderContent = (ruleType: string, ruleGroups: Record<string, NormalizedRule[]>) => {
    return (
      <div key={ruleType} className="mb-m">
        <Heading variant="h3" className="mb-s">
          <FormattedMessage id={ruleType} />
        </Heading>
        <div>
          {Object.entries(ruleGroups).map(([group, rules]) => (
            <div key={group} className="mb-xs">
              <Heading variant="h4">
                <FormattedMessage id={group} />
              </Heading>
              {createRules(rules)}
            </div>
          ))}
        </div>
      </div>
    )
  }

  const renderNotifications = () => {
    return (
      <>
        <NavigationPrompt shouldPrompt={modified()} />
        {updateError && (
          <ErrorNotification id="update-rulesets-error-notification" onClose={reset}>
            <FormattedMessage id="admin.error.message.default" />
          </ErrorNotification>
        )}
        <TimedToast status={status}>
          <FormattedMessage id="admin.info.tallennettu" />
        </TimedToast>
      </>
    )
  }

  const renderHeading = () => {
    const { name, description } = service
    return (
      <>
        <Heading variant="h1" className="mb-m">
          {name}
        </Heading>
        <Paragraph>
          <Text>{description}</Text>
        </Paragraph>
      </>
    )
  }

  const renderRuleSets = () => {
    return (
      <div className="mt-m">
        {modifiedData &&
          Object.entries(groupRules(modifiedData, service, false)).map(([type, ruleTypes]) =>
            ruleSetExpander(type, ruleTypes)
          )}
      </div>
    )
  }

  const renderButtons = () => {
    return (
      <div className="d-flex justify-content-between mt-m">
        <div className="d-flex gap-m">
          <Button
            data-testid="edit-service-save-button"
            disabled={!modified() || !isValid}
            onClick={() => mutate({ uuid: uuid as string, ruleSets: modifiedData })}
          >
            <FormattedMessage id="admin.button.tallenna" />
          </Button>
          <Button
            data-testid="edit-service-back-button"
            variant="secondaryNoBorder"
            onClick={() => navigate("/services")}
          >
            <FormattedMessage id="admin.button.takaisin" />
          </Button>
        </div>
        <div>
          <Button
            data-testid="edit-service-rule-description-button"
            variant="secondaryNoBorder"
            onClick={() => navigate(`/services/service/selected-rules/${service.uuid}`)}
          >
            <FormattedMessage id="admin.button.saanto.kuvaukset" />
          </Button>
        </div>
      </div>
    )
  }

  return (
    <>
      {renderNotifications()}
      {renderHeading()}
      {renderRuleSets()}
      {renderButtons()}
    </>
  )
}

export { EditServicePage }

import React from "react"
import ReactDOM from "react-dom/client"
import "./index.scss"
import { App } from "./App"
import { reportWebVitals } from "./reportWebVitals"

const searchParams = new URLSearchParams(document.location.search)
const showTranslationKeys = searchParams.get("notranslation") === "true"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <App showTranslationKeys={showTranslationKeys} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { useEffect } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { useForm, Controller } from "react-hook-form"
import { Button, Heading, MultiSelect, MultiSelectData, StatusText, TextInput } from "suomifi-ui-components"
import { useAuthorizations, useIssues, useUpdateOfficer } from "../hooks"
import { isValidFinnishCompanyId } from "../utils/id"
import { Auth, Officer, OfficerAuthorization } from "../types"
import { TimedToast } from "../components/TimedToast"
import styles from "./Officers.module.scss"
import { StatusError } from "../StatusError"
import { Divider } from "../components/Divider"
import { validateFinnishSocialSecurityNumber } from "../utils/validation"
import { ErrorNotification } from "../components/ErrorNotification"

type OfficerFormValues = {
  uuid: string | undefined
  personName: string
  personId: string
  organizationId: string
  selectedAuthorizations: MultiSelectData[]
  selectedIssues: MultiSelectData[]
}

type Props = {
  officer?: Officer
}

const authorizationOptions = ["INFOREQUEST", "QUEUEMGMT", "QUEUEREADMGMT", "QUEUEAR", "ORGMANDATESCOPY", "OFFICERADMIN"]
const roleAuthorizationMultiSelectName = "selectedAuthorizations"
const issueAuthorizationMultiSelectName = "selectedIssues"

type MultiSelectName = typeof roleAuthorizationMultiSelectName | typeof issueAuthorizationMultiSelectName

const OfficerForm = (props: Props) => {
  const { officer } = props

  const { data: allIssues, isError: isFetchIssuesError } = useIssues()
  const { data: authorizations, isError: isFetchAuthorizationsError } = useAuthorizations()
  const {
    mutate,
    status: updateStatus,
    isError: isUpdateError,
    isSuccess: isUpdateSuccess,
    error: updateError
  } = useUpdateOfficer()
  const { formatMessage, locale } = useIntl()
  const navigate = useNavigate()

  const isCreation = !officer

  const initialFormValues: OfficerFormValues = officer
    ? {
        uuid: officer.uuid,
        personName: officer.name,
        personId: officer.userId,
        organizationId: officer.organizationId,
        selectedAuthorizations: officer.authorizations
          .filter(a => authorizationOptions.includes(a.roleName))
          .map(a => {
            return {
              uniqueItemId: a.roleName,
              labelText: formatMessage({ id: `admin.officer.authorization.item.${a.roleName.toLowerCase()}` })
            }
          }),
        selectedIssues: officer.authorizations
          .filter(a => a.issue)
          .map(a => {
            return {
              uniqueItemId: a.issue || "",
              labelText: "" // Mandatory in MultiSelectData component. Actual value is set during render
            }
          })
      }
    : {
        uuid: undefined,
        personName: "",
        personId: "",
        organizationId: "",
        selectedAuthorizations: [],
        selectedIssues: []
      }

  const {
    getValues,
    handleSubmit,
    reset,
    control,
    trigger,
    formState: { isValid, isSubmitting, errors }
  } = useForm({ defaultValues: initialFormValues, mode: "onBlur" })

  useEffect(() => {
    if (isCreation && isUpdateSuccess) {
      reset()
    }
  }, [isCreation, isUpdateSuccess, reset])

  const formValuesToOfficer = (values: OfficerFormValues): Officer => {
    const officerManagementAuthorizations: OfficerAuthorization[] = values.selectedIssues.map(a => {
      return { issue: a.uniqueItemId, roleName: "OFFICERMGMT" }
    })
    const otherAuthorizations: OfficerAuthorization[] = values.selectedAuthorizations.map(a => {
      return { roleName: a.uniqueItemId }
    })

    return {
      uuid: values.uuid,
      name: values.personName,
      organizationId: values.organizationId,
      userId: values.personId,
      authorizations: [...officerManagementAuthorizations, ...otherAuthorizations]
    }
  }

  const crossValidateMultiSelectsOneItemIsSelected = (): boolean =>
    getValues(roleAuthorizationMultiSelectName).length > 0 || getValues(issueAuthorizationMultiSelectName).length > 0

  const triggerOtherMultiSelectValidation = (name: MultiSelectName) => trigger(name)

  // eslint-disable-next-line
  const determineErrorMessageId = (error: any): string => {
    if (error instanceof StatusError && error.status === 409) {
      return "admin.error.officer.exist"
    }
    if (isCreation) {
      return "admin.error.officer.add"
    }
    return "admin.error.officer.update"
  }

  const renderNotifications = () => {
    return (
      <>
        {isUpdateError && (
          <ErrorNotification id="update-error-notification">
            <FormattedMessage id={determineErrorMessageId(updateError)} />
          </ErrorNotification>
        )}
        {isFetchAuthorizationsError && (
          <ErrorNotification id="fetch-authorizations-error-notification">
            <FormattedMessage id="admin.error.authorization.fetch" />
          </ErrorNotification>
        )}
        {isFetchIssuesError && (
          <ErrorNotification id="fetch-issues-error-notification">
            <FormattedMessage id="admin.error.issue.fetch" />
          </ErrorNotification>
        )}

        <TimedToast status={updateStatus}>
          <FormattedMessage id={isCreation ? "admin.info.officer.add.success" : "admin.info.officer.update.success"} />
        </TimedToast>
      </>
    )
  }

  if (!authorizations || !authorizations.includes(Auth.OFFICERADMIN) || !allIssues) {
    return null
  }

  const allAuthorizationSelectionItems = authorizationOptions.map(option => {
    return {
      uniqueItemId: option,
      labelText: formatMessage({ id: `admin.officer.authorization.item.${option.toLowerCase()}` })
    }
  })

  const sortIssuesByLabel = (i1: MultiSelectData, i2: MultiSelectData) =>
    i1.labelText.localeCompare(i2.labelText, locale)

  const allIssueSelectionItems = allIssues
    .filter(i => i.active)
    .map(issue => {
      return {
        uniqueItemId: issue.uri,
        labelText: issue.labels[locale]
      }
    })
    .sort(sortIssuesByLabel)

  const validateFinnishCompanyId = (id: string) => {
    if (isValidFinnishCompanyId(id)) {
      return undefined
    }
    return "admin.validation.companyId"
  }

  const renderInputs = () => {
    return (
      <div className="officerFormInputs">
        <div className="my-s">
          <Controller
            name="personName"
            control={control}
            rules={{
              required: "admin.validation.required",
              pattern: { value: /\S+/, message: "admin.validation.required" },
              maxLength: { value: 100, message: "admin.validation.maxLength" }
            }}
            render={({ field: { onChange, onBlur, value, name }, fieldState: { error } }) => (
              <TextInput
                data-testid="officer-form-person-name-input"
                className={styles.officerInput}
                name={name}
                value={value}
                labelText={formatMessage({ id: "admin.officer.form.name.label" })}
                visualPlaceholder={formatMessage({ id: "admin.common.insert" })}
                onChange={onChange}
                onBlur={onBlur}
                status={error ? "error" : "default"}
                statusText={
                  error && formatMessage({ id: error.message }, error.type == "maxLength" ? { value: 100 } : undefined)
                }
              />
            )}
          />
        </div>
        <div className="my-s">
          <Controller
            name="personId"
            control={control}
            rules={{ required: "admin.validation.required", validate: validateFinnishSocialSecurityNumber }}
            render={({ field: { onChange, onBlur, ref, value, name }, fieldState: { error } }) => (
              <TextInput
                data-testid="officer-form-person-id-input"
                name={name}
                value={value}
                labelText={formatMessage({ id: "admin.officer.form.person.id.label" })}
                visualPlaceholder={formatMessage({ id: "admin.common.insert" })}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                status={error ? "error" : "default"}
                statusText={error && formatMessage({ id: error.message })}
                style={{ width: 190 }}
              />
            )}
          />
        </div>
        <div className="my-s">
          <Controller
            name="organizationId"
            control={control}
            rules={{ required: "admin.validation.required", validate: validateFinnishCompanyId }}
            render={({ field: { onChange, onBlur, ref, value, name }, fieldState: { error } }) => (
              <TextInput
                data-testid="officer-form-organization-id-input"
                name={name}
                value={value}
                labelText={formatMessage({ id: "admin.officer.form.company.id.label" })}
                visualPlaceholder={formatMessage({ id: "admin.common.insert" })}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                status={error ? "error" : "default"}
                statusText={error && formatMessage({ id: error.message })}
                style={{ width: 190 }}
                disabled={officer !== undefined}
              />
            )}
          />
        </div>

        <Divider className="my-xl" />
        <Heading variant="h2" className="my-s">
          <FormattedMessage id="admin.header.user.rights" />
        </Heading>
        <div className="my-s">
          <Controller
            name={roleAuthorizationMultiSelectName}
            control={control}
            rules={{
              validate: crossValidateMultiSelectsOneItemIsSelected
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { invalid } }) => (
              <MultiSelect
                data-testid="officer-form-authorizations-multiselect"
                className={styles.officerMultiSelect}
                status={invalid ? "error" : "default"}
                onBlur={onBlur}
                selectedItems={value}
                items={allAuthorizationSelectionItems}
                onItemSelect={issue => {
                  const currentItems: MultiSelectData[] = value
                  let newItems
                  if (currentItems.some(item => item.uniqueItemId === issue)) {
                    newItems = currentItems.filter(item => item.uniqueItemId !== issue)
                  } else {
                    const matchingItem = allAuthorizationSelectionItems.filter(a => a.uniqueItemId === issue)[0]
                    newItems = [...currentItems, matchingItem]
                  }
                  onChange(newItems, {})
                  triggerOtherMultiSelectValidation(issueAuthorizationMultiSelectName)
                }}
                onRemoveAll={() => {
                  onChange([])
                  triggerOtherMultiSelectValidation(issueAuthorizationMultiSelectName)
                }}
                labelText={formatMessage({ id: "admin.virkailijaadminoikeus" })}
                noItemsText={formatMessage({ id: "admin.common.input.filter.no.results" })}
                ariaSelectedAmountText={formatMessage({ id: "admin.officer.authorizations.items.selected.amount" })}
                ariaOptionsAvailableText={formatMessage({
                  id: "admin.officer.authorizations.items.selectable.amount"
                })}
                ariaOptionChipRemovedText={formatMessage({ id: "admin.officer.authorizations.item.removed" })}
                visualPlaceholder={formatMessage({ id: "admin.common.select" })}
                hintText={formatMessage({ id: "admin.common.input.filter.hint" })}
                removeAllButtonLabel={formatMessage({ id: "admin.common.select.remove.all" })}
                chipListVisible
              />
            )}
          />
        </div>
        <div className="my-m">
          <Controller
            name={issueAuthorizationMultiSelectName}
            control={control}
            rules={{
              validate: crossValidateMultiSelectsOneItemIsSelected
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { invalid } }) => (
              <MultiSelect
                data-testid="officer-form-issues-multiselect"
                className={styles.officerMultiSelect}
                status={invalid ? "error" : "default"}
                onBlur={onBlur}
                selectedItems={value
                  .map(item => {
                    const matchingIssue = allIssues?.filter(m => m.uri === item.uniqueItemId)[0]
                    return {
                      ...item,
                      labelText: matchingIssue ? matchingIssue.labels[locale] : ""
                    }
                  })
                  .sort(sortIssuesByLabel)}
                items={allIssueSelectionItems}
                onItemSelect={issue => {
                  const currentItems: MultiSelectData[] = value
                  let newItems
                  if (currentItems.some(item => item.uniqueItemId === issue)) {
                    newItems = currentItems.filter(item => item.uniqueItemId !== issue)
                  } else {
                    const matchingItem = allIssueSelectionItems.filter(a => a.uniqueItemId === issue)[0]
                    newItems = [...currentItems, matchingItem]
                  }
                  onChange(newItems)
                  triggerOtherMultiSelectValidation(roleAuthorizationMultiSelectName)
                }}
                onRemoveAll={() => {
                  onChange([])
                  triggerOtherMultiSelectValidation(roleAuthorizationMultiSelectName)
                }}
                labelText={formatMessage({ id: "admin.virkailijamanageroikeus" })}
                noItemsText={formatMessage({ id: "admin.common.input.filter.no.results" })}
                ariaSelectedAmountText={formatMessage({ id: "admin.officer.authorizations.items.selected.amount" })}
                ariaOptionsAvailableText={formatMessage({
                  id: "admin.officer.authorizations.items.selectable.amount"
                })}
                ariaOptionChipRemovedText={formatMessage({ id: "admin.officer.authorizations.item.removed" })}
                visualPlaceholder={formatMessage({ id: "admin.common.select" })}
                hintText={formatMessage({ id: "admin.common.input.filter.hint" })}
                removeAllButtonLabel={formatMessage({ id: "admin.common.select.remove.all" })}
                chipListVisible
              />
            )}
          />
        </div>
        <StatusText status="error">
          {(errors.selectedIssues || errors.selectedAuthorizations) && (
            <FormattedMessage id="admin.validation.officer.accessRightRequired" />
          )}
        </StatusText>
      </div>
    )
  }

  const renderActionControls = () => {
    return (
      <div className="mt-l">
        <Button
          data-testid="officer-form-save-button"
          type="submit"
          disabled={!isValid || isSubmitting}
          className="me-m"
        >
          <FormattedMessage id="admin.button.tallenna" />
        </Button>
        <Button
          data-testid="officer-form-back-button"
          variant="secondaryNoBorder"
          className="me-m"
          onClick={() => {
            navigate("/officers")
            reset()
          }}
        >
          <FormattedMessage id="admin.button.takaisin" />
        </Button>
      </div>
    )
  }

  return (
    <>
      {renderNotifications()}
      <form
        onSubmit={handleSubmit(() => {
          mutate(formValuesToOfficer(getValues()))
        })}
      >
        {renderInputs()}
        {renderActionControls()}
      </form>
    </>
  )
}

export { OfficerForm }

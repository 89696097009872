import { Route, Routes } from "react-router-dom"
import { OfficersList } from "./OfficersList"
import { OfficerCreatePage } from "./OfficerCreatePage"
import { OfficerUpdatePage } from "./OfficerUpdatePage"

const OfficersRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<OfficersList />} />
      <Route path="/new" element={<OfficerCreatePage />} />
      <Route path="/edit/*" element={<OfficerUpdatePage />} />
    </Routes>
  )
}

export { OfficersRoute }

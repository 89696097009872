const users = "/users/*"
const landing = "/landing"
const unauthorized = "/unauthorized"
const officers = "/officers/*"
const notifications = "/notifications/*"
const services = "/services/*"
const organizations = "/organizations"
const rulesDescriptions = "/rules-descriptions/:id"

export { users, landing, unauthorized, officers, notifications, services, organizations, rulesDescriptions }
